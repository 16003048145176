@charset "UTF-8";
@import "variables";

/**********************

top

***********************/


//-------------------------------------------
//fv
//-------------------------------------------


.top-firstview-contener{
	width: 100%;
	height: 100vh;
	position: relative;
	.top-firstview-movie-box{
		width: 100%;
		height: 100%;
		z-index: -1;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		position: relative;
		.video-fix{
			min-height: 100%;
			min-height: 100vh;
			min-width: 100%;
			min-width: 100vw;
			transition: all 0.3s ease;
			position:relative;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-50%) translateX(-50%);
			-webkit-transform: translateY(-50%) translateX(-50%);
			margin: auto;
			z-index: -1;
		}
	}
	.news-box-container{
		position: absolute;
		bottom:4vh;
		left:2vw;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		.top-firstview-news-box{
			height: fit-content;
			// width: auto;
			width: 100%;
			padding: 10px 18px;
			border-radius:50px;
			background: #FFF;
			display: flex;
			-webkit-align-items: center;
			        align-items: center;
			p.firstview-news{
				display: flex;
				-webkit-align-items:  center;
				        align-items:  center;
				height: 100%;
				span.newtag{
					width: 4em;
					display: flex;
					justify-content: center;
					font-size: 1.2rem;
					color: #FFF;
					background:  $main-color;
					border-radius:40px;
					padding: 0 10px;
					margin-right: 0.6em;
				}
				span.date{
					width: 7em;
					// width: auto;
					display: block;
					font-size: 1.3rem;
					margin-right: 10px;
				}
				span.info{
					display: block;
					font-size: 1.3rem;
				}
			}
			& + .top-firstview-news-box{
				margin-top: 10px;
			}
		}
	}
}

.ver02 .top-firstview-contener {
    width: 100%;
    margin-top: 150px;
    height: calc(100vh - 150px);
    position: relative;
}

.top-concept-contener{
	width: 100%;
	padding: 6vh 0;
	position: relative;
	z-index: -1;
	.top-concept_title{
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		   -ms-flex-direction: column;
		       flex-direction: column;
		    align-items: center;

		.english{
			color:$main-color;
			order:1;
			margin-bottom: 70px;
			position: relative;
			&:before{
				content:"";
				width: 0;
				height: 2px;
				background: $main-color;
				display: inline-block;
				position: absolute;
				bottom:-34px;
				left: 50%;
				margin: auto auto auto -30px;
				transition: all .6s ease 1s;
			}
		}
		.japanese{
			color:$main-color;
			order:2;
			font-size: 4vw;
			margin-bottom: 50px;
		}
		.is-show &{
			.english{
				&:before{
					width: 60px;
				}
			}
		}
	}
	.vertical-writing{
		// line-height: 3em;
		// margin:0 auto;
		// width: 675px;

		// line-height: 3em;
		line-height: 5.5vw;
		width: 100%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		z-index: -1;
		p{
			position: relative;
		}
	}
}





.top-concept-contener .vertical-writing span{
// overflow: hidden;
position: relative;
}

.top-concept-contener .vertical-writing p{
// overflow: hidden;
position: relative;
}

.top-concept-contener .vertical-writing p::before{
	content:"";
	width: 100%;
	height:100%;
	display: inline-block;
	background: #Fff;
	position: absolute;
	z-index:100;
	top:0;
	left:0;
}



.top-concept-contener.is-show .vertical-writing p:nth-of-type(1)::before{
	top:100%;
	// transition: all .6s ease 1.2s;
	transition: all 2s ease 1.2s;
	opacity: 0;
}
.top-concept-contener.is-show .vertical-writing p:nth-of-type(2)::before{
	top:100%;
	// transition: all .6s ease 1.4s;
	transition: all 2s ease 1.4s;
	opacity: 0;
}
.top-concept-contener.is-show .vertical-writing p:nth-of-type(3)::before{
	top:100%;
	// transition: all .6s ease 1.6s;
	transition: all 2s ease 1.6s;
	opacity: 0;
}
.top-concept-contener.is-show .vertical-writing p:nth-of-type(4)::before{
	top:100%;
	// transition: all .6s ease 1.8s;
	transition: all 2s ease 1.8s;
	opacity: 0;
}




.top-project-contener{
	width: 100%;
	margin-bottom: 15vh;
	.topproject-titlebox{
		width: 60vw;
		background: url("../images/top/top-image02.jpg") no-repeat center center;
		background-size: cover;
		padding-bottom: 20vh;
		padding-left: 16vw;
		p.topproject-title{
			display: block;
			width: 100%;
			padding-top: 2vh;
			span.en{
				display: block;
				width: 100%;
				font-size: 6rem;
				color: #FFF;
				font-weight: 600;
				line-height: .7;
				margin-bottom: 8px;
			}
			span.jp{
				display: block;
				width: 100%;
				font-size: 1.6rem;
				color: #FFF;
				font-weight: 600;
			}
		}
	}
	ul.topproject-linklist{
		width: 100%;
		padding-left: 16vw;
		display: flex;
		justify-content: space-between;
		margin-top: -16vh;
		li{
			width: 33%;
			// background: #777  !important;
			height: 23vw;
			padding:  20px;
			position: relative;
	        background-size: cover;
			p.read{
				display: block;
				width: 100%;
				font-size: 1.4rem;
				color: #FFF;
				line-height: 1.9;
				font-weight: 600;
			}
			p.number{
				position: absolute;
				right:10px;
				bottom:0;
				opacity: 0.7;
				img{
					width: 80px
				}
			}
			a.round-viewall{
				display: block;
				position: absolute;
				left:25px;
				bottom:25px;
				background: #FFF;
		        border-radius: 30px;
		        font-size: 1.4rem;
		        color: $main-color;
		        padding: 5px 15px;
		        font-weight: 600;
			}
		}
	}
}


.top-person-contener{
	width: 100%;
	// padding: 0 16vw;
	padding-left: 16vw;
	margin-bottom: 20vh;
	position: relative;
	p.topperson-title{
		width: calc(100% - 16vw);
		margin-right: 16vw;
		span.jp{
			display: block;
			font-size: 1.6rem;
			color: $main-color;
			font-weight: 600;
			margin-bottom: 4px;
		}
		span.en{
			display: block;
			font-size: 6rem;
			color: $main-color;
			font-weight: 600;
			line-height: .7;
			margin-bottom: 8px;
		}
	}
	ul.topperson-link-list{
		width: calc(100% + 50px);
		margin-left: -50px;
		display: flex;
		justify-content: space-between;
		.slick-slide{
			margin-left: 50px;
			li{
				list-style: none;
				// width: 23%;
				background: #888;
				// margin-left: 2%;
				a{
					width: 100%;
					position: relative;
					display: block;
					img{
						width: 100%;
						// height: 30vh;
						// height: 280px;
					}
					.topperson-info{
						position: absolute;
						top:40%;
						left:-10px;
						display: block;
						p.interview-num{
							display: block;
							span.en{
								display: block;
								font-size: 1rem;
								color: $main-color;
								font-weight: 600;
							}
							span.num{
								display: block;
								font-size: 3rem;
								font-weight: 500;
								color: $main-color;
								line-height: .8;
							}
						}
						p.interview-name{
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							width: 100%;
							background:  rgba($main-color , .8);
							padding: 10px;
							span.prof{
								display: block;
								width: 100%;
								font-size: 1.2rem;
								color: #FFF;
								font-weight: 600;
								height: 4rem;
								letter-spacing: 0;
							}
							span.name{
								display: block;
								width: 100%;
								text-align: right;
								font-size: 1.8rem;
								color: #FFF;
								font-weight: 500;
								margin-top: 20px;
							}
						}
					}
				}
			}
		}
	}
	a.roundbtn{
		right:40px;
		bottom:-50px;
		font-size: 1.5rem;
		position: absolute;
		background: $main-color;
		color: #FFF;
		border-radius:50px;
		padding: 5px 10px;
	}
}



.slide-dots{
	position: absolute;
	left: 0;
	bottom: -100px;
	width: calc(100% - 50px - 16vw);
	margin-left: 50px;
	// background: #7f7f80;
	display: flex;
	// padding: 50px 0;
	// text-align: center;
	> li{
		display: inline-block;
		flex-grow: 1;
		// width: 100px;
		// width: auto;
		margin: 0;
		opacity:1 !important;
		button{
			position: relative;
			text-indent: -9999px;
			background-color: transparent;
			border: none;
			cursor: pointer;
			outline: none;
			padding: 0;
			appearance: none;
			width: 100%;
			height:5px;
			&:before{
				content: '';
				font-size: 20px;
				text-indent: 0px;
				position: absolute;
				top: 0;
				left: 0;
				// background: #FFF;
				background: #7f7f80;
				width: 100%;
				height:5px;
			}
		}
		&.slick-active{
			button:before{
				content: '';
				background: $main-color;
			}
		}
	}
	// .is-show &{
	// 	li:nth-of-type(1){
	// 		opacity:1;
	// 		transition: all .4s ease .6s;
	// 	}
	// 	li:nth-of-type(2){
	// 		opacity:1;
	// 		transition: all .4s ease .8s;
	// 	}
	// 	li:nth-of-type(3){
	// 		opacity:1;
	// 		transition: all .4s ease 1s;
	// 	}
	// 	li:nth-of-type(4){
	// 		opacity:1;
	// 		transition: all .4s ease 1.2s;
	// 	}
	// 	li:nth-of-type(5){
	// 		opacity:1;
	// 		transition: all .4s ease 1.4s;
	// 	}
	// 	li:nth-of-type(6){
	// 		opacity:1;
	// 		transition: all .4s ease 1.6s;
	// 	}
	// 	li:nth-of-type(7){
	// 		opacity:1;
	// 		transition: all .4s ease 1.8s;
	// 	}
	// 	li:nth-of-type(8){
	// 		opacity:1;
	// 		transition: all .4s ease 2s;
	// 	}
	// 	li:nth-of-type(9){
	// 		opacity:1;
	// 		transition: all .4s ease 2.2s;
	// 	}
	// 	li:nth-of-type(10){
	// 		opacity:1;
	// 		transition: all .4s ease 2.4s;
	// 	}
	// 	li:nth-of-type(11){
	// 		opacity:1;
	// 		transition: all .4s ease 2.6s;
	// 	}
	// 	li:nth-of-type(12){
	// 		opacity:1;
	// 		transition: all .4s ease 2.8s;
	// 	}
	// }
}






.top-pickup-contener{
	width: 100%;
	padding: 0 0 8vh 16vw;
	margin-bottom: 10vh;
	overflow: hidden;
	p.toppickup-title{
		display: flex;
		width: 100%;
		justify-content: space-between;
		position: relative;
		span.jp{
			display: block;
			font-size: 1.6rem;
			color: $main-color;
			font-weight: 600;
		}
		span.en{
			position: absolute;
			left:0;
			top:-18px;
			transform:rotate(90deg);
			transform-origin:left bottom;
			display: block;
			font-size: 6rem;
			color: $main-color;
			font-weight: 600;
			line-height: .7;
			margin-bottom: 0px;
		}
	}
	ul.toppickup-link-list{
		// width: 105% !important;
		// padding-left: 5vw;
		margin-left: 5vw;
		width: 79vw;
		display: flex;
		justify-content: space-between;
		li{
			width: calc((79vw / 3) - 5px);
			// width: 33%;
			// height: 19vw;
			height: 300px;
			background: #888;
			margin-left: 5px;
			&.pick01{
				background: url("../images/top/top_pickup01.jpg")no-repeat center center;
				background-size: cover;
			}
			&.pick02{
				background: url("../images/top/top_pickup03.jpg")no-repeat center center;
				background-size: cover;
			}
			&.pick03{
				background: url("../images/top/top_pickup02.jpg")no-repeat center center;
				background-size: cover;
			}
			&.pick04{
				background: url("../images/top/top_pickup04.jpg")no-repeat center center;
				background-size: cover;
			}
			a{
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 0 .7vw;
				p.toppickup-linktitle{
					display: block;
					width: 100%;
					font-size: 3rem;
					font-weight: 600;
					color: #FFF;
					line-height: 1.4;
					// transform:translateY(-10px);
				}
				p.toppickup-linkread{
					display: block;
					width: 100%;
					text-align: right;
					position: relative;
					padding: 20px 30px 20px 0 ;
					font-size: 1.5rem;
					font-weight: 600;
					color: #FFF;
					&::after{
						content:'＞';
						position: absolute;
						right:10px;
						top:50%;
						transform:translateY(-50%)scaleX(.7);
						font-weight: 900;
					}
				}
			}
		}
		.slick-list{
			width: 100%;
		}
		.slick-slide{
			margin-right: 5px;
		}
	}
}


.top-recruit-contener{
	width: 100%;
	margin-bottom: 10vh;
	.toprecruit-titlebox{
		width: 100%;
		padding: 0 16vw;
		background: url("../images/top/top-image11.jpg") no-repeat center center;
		background-size: cover;
		height: 25vh;
		z-index: -1;
		p.toprecruit-title{
			display: block;
			width: 100%;
			padding-top: 2vh;
			span.en{
				display: block;
				width: 100%;
				font-size: 6rem;
				color: #FFF;
				font-weight: 600;
				line-height: .7;
				margin-bottom: 8px;
			}
			span.jp{
				display: block;
				width: 100%;
				font-size: 1.6rem;
				color: #FFF;
				font-weight: 600;
			}
		}
	}
	ul.toprecruit-link-list{
		width: 85%;
		display: flex;
        justify-content: space-between;
        transform:translateY(-6vh);
		margin: 0 auto;
		li{
			width: 48%;
			a{
				display: flex;
				width: 100%;
				.toprecruit-link-img{
					width: 50%;
					img{
					width: 100%;
					height: 100%;
					background: #777;
					}
				}
				p.toprecruit-linkread{
					width: 50%;
					display: flex;
					height: auto;
			        flex-direction: column;
			        justify-content: flex-end;
			        background: $main-color;
			        span{
			        	display: block;
						width: 100%;
						text-align: right;
						position: relative;
						padding: 20px 30px 20px 10px ;
						font-size: 1.5rem;
						font-weight: 600;
						color: #FFF;
						&::after{
							content:'＞';
							position: absolute;
							right:10px;
							top:50%;
							transform:translateY(-50%)scaleX(.7);
							font-weight: 900;
						}
			        }
				}
			}
		}
	}
}




































@import "top-res";
